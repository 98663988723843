<template>
    <div class="barraStatus p-2 d-flex align-items-center justify-content-start">
        <div class="log-app d-flex flex-column justify-content-between align-items-center">
            <img class="srklogo mx-2" src="../assets/srkLogoWhite.png" alt="srk_logo">
            <div class="Titulo d-flex flex-column align-items-center">
                <div class="appTitle">TimeSheet</div>
                <div class="appVersion">&nbsp;Versión 2.1</div>
            </div>
        </div>

        <div class="d-flex flex-grow-1">
            <div class="appTitle mx-auto fs-2">{{ store.title }}</div>
        </div>

        <span class="appUser ms-auto">{{ store.nombre }}</span>
    </div>
</template>

<script setup>
import store from '../store/index'
import { onMounted } from 'vue'

onMounted( () => {
    console.log('Mounted StatusView')
})



</script>

<style>


.srklogo {
    width: 6rem;
}

.appUser {
    font-size: 0.8rem;
    color: rgb(245, 206, 161);
}

.appTitle {
    font-size: 1.2rem;
    color:white;
}

.appVersion {
    font-size: 0.8rem;
    color: rgb(245, 206, 161);
}

.barraStatus{
    background-color: rgb(243,112,32);
    border-radius: 5px;
} 
</style>